<template>
	<router-view class="view view--page page" name="content" />
</template>

<script>
export default {
	name: 'ViewPage',
	watch: {
		$route: {
			immediate: true,
			handler: function (val) {
				const { hash } = val
				if (!hash) return
				const el = document.querySelector(hash)
				if (!el || !this.$el) return
				let target = this.$el.querySelector('.article-wrapper')
				if (!target) return

				target.scrollTo({
					top: el.getBoundingClientRect().top,
					left: 0,
					behavior: 'smooth',
				})
			},
		},
	},
}
</script>

<style scoped lang="scss">
.page {
	height: 100vh;
	overflow: hidden;
	position: relative;
	width: 100%;
}
</style>
